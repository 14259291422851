<template>
  <b-card>
    <AppCollapse class="mb-50">
      <AppCollapseItem
        title
        :is-visible="true"
        class-body="p-0"
        class-header="p-0"
      >
        <template #header>
          <h4 class="card-title font-weight-bolder py-50">
            {{ $t('filters') }}
          </h4>
        </template>
        <b-row
          align-v="center"
          align-h="start"
        >
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.filters.from')"
            >
              <flat-pickr
                v-model="filter.fromDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: filter.toDate ? filter.toDate: today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.filters.to')"
            >
              <flat-pickr
                v-model="filter.toDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
              <!-- @input="val => $emit('update:endDate', val)" -->
            </b-form-group>
          </b-col>

          <!-- ANCHOR trạng thái -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('supportReq.status.title')"
            >
              <v-select
                v-model="filter.status"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="status"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
              >
                <template #option="data">
                  {{ $t(`supportReq.status.${data.label}`) }}
                </template>

                <template #selected-option="data">
                  {{ $t(`supportReq.status.${data.label}`) }}
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Tên khách hàng -->
          <b-col
            v-if="isRoleF1"
            cols="12"
            md="2"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.buyer.name')"
            >
              <b-form-input
                v-model="filter.searchText"
                :debounce="500"
                :placeholder="$t('invoice.ph.buyerName')"
                lazy-formatter
                @input="val => $emit('update:buyerName', val)"
                @keyup.enter="$emit('refresh')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="flex-fill"
            align-self="end"
          >
            <div class="d-flex align-items-center justify-content-end gap-1">
              <!-- ANCHOR Button btnReload. -->
              <b-button
                id="btnReload"
                v-b-tooltip.hover.v-dark.window="$t('supportReq.btn.reload')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-info"
                size="sm"
                @click="()=>{$emit('refresh')}"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="reload"
                    size="16"
                  />
                  {{ $t('supportReq.btn.reload') }}
                </span>
              </b-button>

              <!-- ANCHOR Button Search -->
              <b-button
                variant="primary"
                size="sm"
                class="p-50"
                @click="$emit('refresh')"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="search"
                    size="16"
                  />
                  {{ $t('search') }}
                </span>
              </b-button>

              <!-- ANCHOR Button Clear Search Filters -->
              <b-button
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="()=>$emit('refresh', true)"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="delete"
                    size="16"
                  />
                  {{ $t('invoice.create.selectTicket.clearFilter') }}
                </span>
              </b-button>
              <!-- ANCHOR Button Clear Search Filters -->
              <b-button
                v-if="isRoleF2"
                variant="outline-info"
                size="sm"
                class="p-50"

                @click="() => {
                  $emit('onCreate')
                  $bvModal.show('create-support-modal')}"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="plusCircleOutline"
                    size="16"
                  />
                  {{ $t('bankAccount.add') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </AppCollapseItem>
    </AppCollapse>
  </b-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
    flatPickr,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    status: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    return {
      Vietnamese,
      today,
      isRoleF1,
      isRoleF2,
    }
  },
}
</script>

<style lang="scss">
</style>
